<template>
  <v-dialog
    v-model="visible"
    @keydown.esc="close"
    persistent
    scrollable
    max-width="430"
    style="z-index: 6"
  >
    <v-form ref="addCategoryForm" @submit.prevent="submit">
      <v-card
        class="overflow-auto pb-5"
        :class="{ 'mobile-card': $vuetify.breakpoint.smAndDown }"
      >
        <v-card-title class="d-flex" style="vertical-align: middle">
          <v-row class="pa-3 mb-2">
            <h2 class="text--secondary">Kategorija</h2>
            <v-spacer></v-spacer>
            <v-icon large @click="close">mdi-close</v-icon>
          </v-row>
        </v-card-title>

        <v-card-text>
          <v-container>
            <v-row>
              <v-col class="py-0">
                <v-text-field
                  outlined
                  autofocus
                  label="Naziv"
                  v-model="item.name"
                  :rules="[rules.req]"
                >
                </v-text-field>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="8">
                <v-img
                  ref="picture"
                  :src="item.picture"
                  contain
                  height="190"
                  :lazy-src="require('@/assets/no-item.jpg')"
                >
                  <template v-slot:placeholder>
                    <v-img :src="require('@/assets/no-item.jpg')"></v-img>
                  </template>
                </v-img>
              </v-col>
            </v-row>
            <v-row class="justify-center">
              <v-col cols="10">
                <v-file-input
                  label="Odaberi sliku kategorije"
                  style="overflow: hidden"
                  @change="onFileChange"
                  ref="imageUp"
                  clearable
                  @click:close="onFileChange"
                >
                </v-file-input>
              </v-col>
            </v-row>
          </v-container>
        </v-card-text>

        <v-card-actions class="d-flex justify-center">
          <v-btn
            class="white--text okButton"
            height="45"
            :loading="submitting"
            :disabled="submitting"
            type="submit"
          >
            Spremi
          </v-btn>
        </v-card-actions>
      </v-card>
    </v-form>
  </v-dialog>
</template>
<script>
import { df, auth } from '@/plugins/firebase'
import { v4 as uuidv4 } from 'uuid'
import rules from '@/plugins/rules'
import state from '@/state'
import { clone } from '@/plugins/utils'
import requestChecker from '@/mixins/requestChecker'

export default {
  name: 'AddCategory',
  inject: ['showMsgBox', 'showLoader', 'hideLoader'],
  mixins: [requestChecker],
  data: () => ({
    visible: false,
    name: undefined,
    submitting: false,
    item: {},
    pictureDirty: false,
    rules: {
      req: rules.req('Required field')
    },
    defaultUrl: ''
  }),
  methods: {
    open (item) {
      this.item = clone(item)
      this.visible = true
      this.defaultUrl = clone(item.picture)
      this.registerRequestParams(this.item)
    },
    close () {
      this.$emit('close')
      this.$refs.imageUp.reset()
      this.visible = false
    },
    createImage (file) {
      const reader = new FileReader()

      reader.onload = (e) => {
        if (e.target.result) {
          this.item.picture = e.target.result
        }
      }
      reader.readAsDataURL(file)
    },
    onFileChange (file) {
      if (!file) {
        const defaultUrl = this.defaultUrl
          ? this.defaultUrl
          : require('@/assets/unknown_profile.jpg')
        this.item.picture = defaultUrl

        if (!this.defaultUrl) {
          setTimeout(() => {
            this.item.picture = ''
          }, 10)
        }
        return
      }

      if (file.size > 500000) {
        this.showMsgBox({
          text: 'Prevelika datoteka, maksimalna dopuštena veličina datoteke je 500 KB.',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        this.item.picture = ''
        this.$refs.imageUp.reset()
        return
      }

      this.createImage(file)
    },
    submit () {
      const that = this
      if (!this.$refs.addCategoryForm.validate()) return
      //   this.showLoader()
      this.submitting = true
      const reqId = uuidv4()

      if (this.item.picture !== this.defaultUrl) {
        if (this.item.picture.split(',').length > 0) {
          this.item.picture = this.item.picture.split(',')[1]
        }
      }

      const payload = {
        action: {
          operation: 'update',
          entity: 'category',
          resource_id: this.item.id,
          params: {
            name: this.item.name,
            picture: this.item.picture
          }
        }
      }

      const params = this.getCleanParams(this.item)
      Object.keys(params).forEach((key) => {
        delete payload.action.params[key]
      })

      payload.action.params.company_id = state.getCurrentCompany().id

      that.$emit('processing', true)
      that.$emit('edit', { ...payload.action.params, action: 'EDIT' })
      try {
        df.doc(`request/${reqId}`)
          .set({
            user_id: `${auth.currentUser.uid}`,
            device_id: 'web',
            created: `${new Date().getTime()}`,
            type: 'company',
            payload: btoa(
              unescape(encodeURIComponent(JSON.stringify(payload)))
            )
          })
          .then(function () {
            setTimeout(() => {
              that.$refs.addCategoryForm.reset()
              that.submitting = false
              that.visible = false
            }, 100)
          })
          .catch(function (err) {
            that.$emit('processing', false)
            that.showMsgBox({
              text: err && err !== '' ? err : 'An error has occurred',
              actions: ['cancel'],
              cancelBtnText: 'OK',
              color: 'error'
            })
            that.submitting = false
          })
      } catch (err) {
        that.$emit('processing', false)
        that.showMsgBox({
          text: err && err !== '' ? err : 'An error has occurred',
          actions: ['cancel'],
          cancelBtnText: 'OK',
          color: 'error'
        })
        that.submitting = false
      }
    }
  }
}
</script>
