var render = function () {var _vm=this;var _h=_vm.$createElement;var _c=_vm._self._c||_h;return _c('div',{staticClass:"fill-height",staticStyle:{"width":"100%"}},[_c('v-row',{staticClass:"pt-3 pr-3"},[_c('v-spacer'),_c('Search-box',{attrs:{"emitDestination":_vm.searchEmitDestination,"searchPlaceholder":"Pretraži kategorije"}})],1),_c('v-container',[(_vm.listView)?_c('v-row',{staticClass:"d-flex justify-center",staticStyle:{"margin-top":"0px"}},[_c('v-col',{class:['px-0'],attrs:{"cols":"12","sm":"11","lg":"11"}},_vm._l((_vm.currentCategories),function(item,i){return _c('v-card',{key:i,attrs:{"hover":""}},[_c('v-row',{class:[
              'ma-0',
              item.status === 'OK'
                ? 'status-ok'
                : ("" + (item.status === 'PROCESSING'
                      ? 'status-processing'
                      : 'status-failed')) ]},[(_vm.density !== 'greater')?_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"1"}},[(item.picture)?_c('v-img',{attrs:{"src":item.picture,"contain":"","height":_vm.density === 'normal' ? '48' : '68'}}):_c('v-img',{attrs:{"src":require("@/assets/no-item.jpg"),"height":_vm.density === 'normal' ? '48' : '68'}})],1):_vm._e(),_c('v-col',{class:[_vm.densityPadding, 'flex-grow-1'],staticStyle:{"min-width":"100px","max-width":"100%"},attrs:{"cols":"3","sm":"3","md":"5","lg":"5"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Naziv kategorije")]),_c('div',{ref:("hover-" + (item.id)),refInFor:true,staticClass:"text-subtitle-1 one-liner-text",attrs:{"id":("hover-" + (item.id))},on:{"mouseover":function($event){return _vm.hoverOver(("hover-" + (item.id)), item)},"mouseleave":function($event){item[("hover-" + (item.id))] = false}}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
            var on = ref.on;
return [_c('div',_vm._g({},on))]}}],null,true),model:{value:(item[("hover-" + (item.id))]),callback:function ($$v) {_vm.$set(item, ("hover-" + (item.id)), $$v)},expression:"item[`hover-${item.id}`]"}},[_c('div',{staticStyle:{"max-width":"300px"}},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")])])],1),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"3","lg":"3"}},[_c('div',{staticClass:"caption grey--text"},[_vm._v("Status kategorije")]),_c('div',{class:[
                  item.status === 'OK'
                    ? 'success--text'
                    : ("" + (item.status === 'PROCESSING'
                          ? 'info--text'
                          : 'error--text')) ]},[_vm._v(" "+_vm._s(item.status)+" ")])]),_c('v-col',{class:[_vm.densityPadding],attrs:{"cols":"3","sm":"3","md":"3","lg":"3"}},[_c('div',{staticClass:"caption grey--text text-center"},[_vm._v("Akcije")]),_c('div',{staticClass:"text-center"},[_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"x-small":_vm.density === 'greater',"icon":"","disabled":item.status === 'PROCESSING'},on:{"click":function($event){return _vm.openEditCategoryForm(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditCategoryForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi kategoriju")])])],1)])],1),_c('v-divider')],1)}),1)],1):_c('v-row',{staticClass:"d-flex justify-center align-center fill-height ma-0 pa-0"},_vm._l((_vm.currentCategories),function(item,i){return _c('v-card',{key:i,staticClass:"align-center ma-5",attrs:{"hover":"","height":"300","width":"250"}},[(item.picture)?_c('v-img',{attrs:{"height":"190","width":"250","src":item.picture}}):_c('v-img',{attrs:{"height":"190","width":"250","src":require("@/assets/no-item.jpg")}}),_c('div',{staticClass:"mr-4 ml-4"},[_c('div',[_c('h3',{staticClass:"selling-point-title-text one-liner-text"},[_vm._v(" "+_vm._s(_vm.$options.filters.capitalize(item.name))+" ")]),_c('p',{staticClass:"grey--text mb-1 selling-point-text one-liner-text"},[_vm._v(" Status: "+_vm._s(item.status)+" ")])])]),_c('v-divider'),_c('v-card-actions',[_c('v-spacer'),_c('v-tooltip',{attrs:{"bottom":""},scopedSlots:_vm._u([{key:"activator",fn:function(ref){
                var on = ref.on;
                var attrs = ref.attrs;
return [_c('v-btn',_vm._g(_vm._b({attrs:{"disabled":item.status === 'PROCESSING',"icon":""},on:{"click":function($event){return _vm.openEditCategoryForm(item)},"keypress":function($event){if(!$event.type.indexOf('key')&&_vm._k($event.keyCode,"enter",13,$event.key,"Enter")){ return null; }return _vm.openEditCategoryForm(item)}}},'v-btn',attrs,false),on),[_c('v-icon',[_vm._v("mdi-pencil")])],1)]}}],null,true)},[_c('span',[_vm._v("Uredi kategoriju")])])],1)],1)}),1)],1),_c('add-category',{ref:"addCategory",on:{"add":_vm.refreshCategory}}),_c('edit-category',{ref:"editCategory",on:{"edit":_vm.refreshCategory}})],1)}
var staticRenderFns = []

export { render, staticRenderFns }