<template>
  <div style="width: 100%" class="fill-height">
    <v-row class="pt-3 pr-3">
      <v-spacer></v-spacer>
      <Search-box
        :emitDestination="searchEmitDestination"
        searchPlaceholder="Pretraži kategorije"
      ></Search-box>
    </v-row>
    <v-container>
      <v-row
        v-if="listView"
        style="margin-top: 0px"
        class="d-flex justify-center"
      >
        <v-col cols="12" sm="11" lg="11" :class="['px-0']">
          <v-card v-for="(item, i) in currentCategories" :key="i" hover>
            <v-row
              :class="[
                'ma-0',
                item.status === 'OK'
                  ? 'status-ok'
                  : `${
                      item.status === 'PROCESSING'
                        ? 'status-processing'
                        : 'status-failed'
                    }`,
              ]"
            >
              <v-col
                cols="3"
                sm="3"
                md="1"
                :class="[densityPadding]"
                v-if="density !== 'greater'"
              >
                <v-img
                  v-if="item.picture"
                  :src="item.picture"
                  contain
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
                <v-img
                  v-else
                  src="@/assets/no-item.jpg"
                  :height="density === 'normal' ? '48' : '68'"
                ></v-img>
              </v-col>
              <v-col
                cols="3"
                sm="3"
                md="5"
                lg="5"
                style="min-width: 100px; max-width: 100%"
                :class="[densityPadding, 'flex-grow-1']"
              >
                <div class="caption grey--text">Naziv kategorije</div>
                <div
                  class="text-subtitle-1 one-liner-text"
                  @mouseover="hoverOver(`hover-${item.id}`, item)"
                  @mouseleave="item[`hover-${item.id}`] = false"
                  :id="`hover-${item.id}`"
                  :ref="`hover-${item.id}`"
                >
                  {{ $options.filters.capitalize(item.name) }}
                </div>
                <v-tooltip v-model="item[`hover-${item.id}`]" bottom>
                  <!--Fake activator to avoid an attach property which is not working properly -->
                  <template v-slot:activator="{ on }">
                    <div v-on="on"></div>
                  </template>
                  <div style="max-width: 300px">
                    {{ $options.filters.capitalize(item.name) }}
                  </div>
                </v-tooltip>
              </v-col>
              <v-col cols="3" sm="3" md="3" lg="3" :class="[densityPadding]">
                <div class="caption grey--text">Status kategorije</div>

                <div
                  :class="[
                    item.status === 'OK'
                      ? 'success--text'
                      : `${
                          item.status === 'PROCESSING'
                            ? 'info--text'
                            : 'error--text'
                        }`,
                  ]"
                >
                  {{ item.status }}
                </div>
              </v-col>
              <v-col cols="3" sm="3" md="3" lg="3" :class="[densityPadding]">
                <div class="caption grey--text text-center">Akcije</div>
                <div class="text-center">
                  <v-tooltip bottom>
                    <template v-slot:activator="{ on, attrs }">
                      <v-btn
                        :x-small="density === 'greater'"
                        v-on="on"
                        v-bind="attrs"
                        icon
                        :disabled="item.status === 'PROCESSING'"
                        @click="openEditCategoryForm(item)"
                        @keypress.enter="openEditCategoryForm(item)"
                      >
                        <v-icon>mdi-pencil</v-icon>
                      </v-btn>
                    </template>
                    <span>Uredi kategoriju</span>
                  </v-tooltip>
                </div>
              </v-col>
            </v-row>
            <v-divider></v-divider>
          </v-card>
        </v-col>
      </v-row>
      <v-row
        v-else
        class="d-flex justify-center align-center fill-height ma-0 pa-0"
      >
        <v-card
          class="align-center ma-5"
          v-for="(item, i) in currentCategories"
          :key="i"
          hover
          height="300"
          width="250"
        >
          <v-img
            height="190"
            width="250"
            v-if="item.picture"
            :src="item.picture"
          >
          </v-img>
          <v-img height="190" width="250" v-else src="@/assets/no-item.jpg">
          </v-img>
          <div class="mr-4 ml-4">
            <div>
              <h3 class="selling-point-title-text one-liner-text">
                {{ $options.filters.capitalize(item.name) }}
              </h3>
              <p class="grey--text mb-1 selling-point-text one-liner-text">
                Status: {{ item.status }}
              </p>
            </div>
          </div>
          <v-divider></v-divider>
          <v-card-actions>
            <v-spacer></v-spacer>
            <v-tooltip bottom>
              <template v-slot:activator="{ on, attrs }">
                <v-btn
                  v-on="on"
                  v-bind="attrs"
                  :disabled="item.status === 'PROCESSING'"
                  icon
                  @click="openEditCategoryForm(item)"
                  @keypress.enter="openEditCategoryForm(item)"
                >
                  <v-icon>mdi-pencil</v-icon>
                </v-btn>
              </template>
              <span>Uredi kategoriju</span>
            </v-tooltip>
          </v-card-actions>
        </v-card>
      </v-row>
    </v-container>
    <add-category ref="addCategory" @add="refreshCategory"></add-category>
    <edit-category ref="editCategory" @edit="refreshCategory"></edit-category>
  </div>
</template>
<script>
import { df } from '@/plugins/firebase'
import { clone } from '@/plugins/utils'
import state from '@/state'
import flow from '@/mixins/flow'
import AddCategory from '@/modules/company/components/AddCategory'
import EditCategory from '@/modules/company/components/EditCategory'
import applicationSettings from '@/mixins/applicationSettings' // don't move.. detaches firebase listeners
import EventBus from '@/plugins/event-bus'

export default {
  inject: ['showLoader', 'hideLoader'],
  mixins: [flow, applicationSettings],
  components: { AddCategory, EditCategory },
  data: () => ({
    loading: false,
    listeners: [],
    categories: [],
    currentCategories: [],
    searchEmitDestination: 'categoriesSearch'
  }),
  mounted () {
    const listener = df.doc(`company_categories/${state.getCurrentCompany().id}`).onSnapshot(doc => {
      if (doc && doc.data() && doc.data().categories && Object.keys(doc.data().categories).length > 0) {
        this.categories = Object.keys(doc.data().categories).map(key => {
          return { ...doc.data().categories[key], [`hover-${doc.data().categories[key].id}`]: false }
        })
        this.categories.sort((a, b) => a.name > b.name ? 1 : -1)
      }
    })

    EventBus.$on(this.searchEmitDestination, (searchObject) => {
      this.searchAll(searchObject.searchString)
    })

    this.listeners.push(listener)
  },
  computed: {
    listView () {
      return state.isListView()
    }
  },
  watch: {
    categories: {
      deep: true,
      handler (nv, ov) {
        if (nv !== ov) {
          let currentCategories = []
          if (this.filter && this.filter !== '') {
            currentCategories = nv.filter(category => category.name.toLowerCase().includes(this.filter.toLowerCase()))
          } else {
            currentCategories = nv
          }
          this.currentCategories = clone(currentCategories.sort((a, b) => a.name.localeCompare(b.name)))
        }
      }
    }
    // filter (val) {
    //   if (!val) {
    //     this.currentCategories = this.categories
    //   } else {
    //     this.currentCategories = this.categories.filter(category => category.name.toLowerCase().includes(val.toLowerCase()))
    //   }
    // }
  },
  methods: {
    searchAll (val) {
      if (!val) {
        this.currentCategories = this.categories
      } else {
        this.currentCategories = this.categories.filter(category => category.name.toLowerCase().includes(val.toLowerCase()))
      }
    },
    openAddCategoryForm () {
      this.$refs.addCategory.open()
    },
    openEditCategoryForm (item) {
      this.$refs.editCategory.open(item)
    },
    hoverOver (ref, item) {
      const container = this.$refs[ref]
      if (!container || !container[0]) return
      if (container[0].associateHeight < container[0].scrollHeight) {
        item[ref] = true
      }
    },
    refreshCategory (item) {
      if (item.action === 'ADD') {
        this.categories.unshift(
          { ...item, ...{ status: 'PROCESSING' } }
        )
        this.filter = item.name
      } else if (item.action === 'EDIT') {
        this.categories = this.categories.map(_item => {
          if (_item.id === item.id) {
            _item.name = item.name
            _item.status = 'PROCESSING'
          }
          return _item
        })
        this.filter = item.name
      }
    }
  }
}
</script>
